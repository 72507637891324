import { useParams } from "react-router-dom"
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Booking(){
    const params = useParams();

    const Parks = [
        {
            id:1,
            title:'Jim Corbett National Park, Ramnagar, Uttarakhand, India',
            desc:`Corbett Tiger Reserve has a total area of 1288.31 Sq km over Pauri Nainital and Almora Districts of Uttarakhand state. Shiwalik Hills and Terai regions with streams rivers ridges and grasslands provide excellent sustenance for wild life species.<br/><br/>
            Corbett Tiger Reserve has multiple Zones for Tourists who enjoy Gypsy Safari and Canter Safari looking for Royal Bengal Tigers and Asiatic Elephants. <br/><br/>
            The safari zones are Bijrani, Jhirna, Dhela, Dhikala, Pakhro, Lohachaur,, Durgadevi and Sitabani.<br/><br/>
            Experience helps when traveling to any Reserve.<br/><br/>
            For example if you have booked Pakhro and Jhirna in the same trip it means you are only going to enjoy one of the two as Pakhro safari is accessible from Kotdwar city while Jhirna safari is accessible from Ramnagar city.<br/><br/>
            Night stay inside the Reserve is managed by Corbett Tiger Reserve and promises the best experience to tourists at extremely low cost compared to day safari. Most popular is Dhikala zone which has both Forest Rest House Rooms and Dormitory for which booking starts 45 days in advance from the date of park opening.<br/><br/>
            Corbett Tiger Reserve Jhirna and Dhela Zones are open year around for Day Safari. Important to note during monsoon heavy rain can cause cancelation of booked Safari with no refund to tourists.<br/><br/>
            Dhikala zone is open from November 15 to June 15 and only offers night stay safari optiin which can be booked for 6 tourists and Day Safari Canter rides which can be booked individually. The first canter Safari leaves 6 am in the morning and the second leaves 11 am in the morning from Ramnagar Booking Office of Corbett Tiger Reserve.<br/><br/>
            The Bijrani zone opens from October 15 to June 30 for day visits.<br/><br/>
            The Pakhro Zone accessible from Kotdwar is open from November 15 to June 15 for  both day safari and Night Stays.<br/><br/>
            The Lohachaur Zone accessible from Ramnagar is open from November 15 to June 15 for  both day safari and Night Stays.<br/><br/>
            <b>Jim Corbett Tiger Reserve</b> <br/> <br/>
            <b>Nearest Airport: </b> IGI New Delhi, India.<br/>
            <b>Railway Station: </b>Ramnagar, Uttarakhand, India.<br/>
            <b>By Road:</b> New Delhi to Ramnagar via Moradabad 5 hours journey.<br/><br/>
            Ramnagar city has 5 star hotels and resorts and multiple homestays and smaller hotels spread upto 80 kms from Ramnagar city.<br/><br/> <b>Call or fill our contact us form</b> for personalized guidance for booking Gypsy and Hotels in Ramnagar.
            `,
            bookdetails:'https://corbettonline.uk.gov.in/corbettDefaultNew.aspx'
        },
        {
            id:2,
            title:'Panna National Park, Panna Khajuraho,Madhya Pradesh,India',
            desc:`Panna Tiger Reserve a land of waterfalls diamonds and forests where an unpolluted Ken River gives life to the flora and fauna.<br/><br/>
            Where the Pandavas dwelled during their Vanvas and where Tigers roamed once and roam freely today.<br/><br/>
           Panna Tiger Reserve comprises of 576 Sq km Core and 1021 sq km Buffer area in Panna and Chhatarpur districts which had lost all its Tigers in 2009 however successful reintroduction of tigers in 2009 has ensured that Panna Tiger population boosts a healthy number of upto 60 Tigers.<br/><br/>
           Panna Tiger Reserve has 2 Core Zones Madla and Hinauta and 2 Buffer Zones Jhinna and Akola.<br/><br/>
           Jungle Safari for Madla ans Hinauta Zones is open from October 15 to July 14.<br/><br/>
           Jungle Safari for Jhinna and Akola Buffer is open throughout the year. <br/><br/>
           Night Safari is also available in Jhinna and Akola Zones throughout the year and gives insight into the Jungle and its animals at night.<br/><br/>
           Panna Tiger Reserve is only 25 kms from Khajuraho Temples which are a World Heritage Monument and frequented by tourists from around the world.<br/><br/>
           Panna Tiger Reserve is home to Pandava Caves and Waterfalls where the Pandavas had taken shelter during their Vanvas. Sloth bears and Tigers frequent the spot and their presence can be seen on the Teak tress where they leave their claw marks as proof of their presence.<br/><br/>
           Panna Tiger Reserve is close to Reneh Waterfalls also known as Ken Gharial Sanctuary which is a major tourist attraction only 18 kms away.<br/><br/>
           Tigers Hyenas Sloth Bears Leopards Wild Boar Porcupine Chital Deers Sambhar Deers Nilgai Vultures and Crocodiles with numerous Bird Species provide the Wildlife Lover an unmatched Wilderness Experience.
           Panna Tiger Reserve has 5 star resorts and Hotels and Homestays and Small Hotels spread across Khajuraho and Panna Tiger Reserve.<br/><br/>
           Experience is helpful when traveling to any Reserve.<br/><br/>
           For example if you have booked your stay in Khajuraho and your Safari is from Hinauta Core Gate you need 90 minutes or more to reach the gate. Park safari entry time is 6 am normally and if you reach at 7.30 am have lost valuable time as normally the first vehicles entering have highest probability of sighting Tigers.<br/><br/>
           So <b>Call us or fill our Contact us form</b> to help plan and book Gypsy Safari and Stay in Panna Tiger Reserve.`,
            bookdetails:'https://forest.mponline.gov.in/'
        },
        {
            id:3,
            title:'Bandhavgarh National Park, Umaria,Madhya Pradesh, India',
            desc:'Bandhavgarh National Park desc',
            bookdetails:'https://forest.mponline.gov.in/' 
        },
        {
            id:4,
            title:'Ranthambore National Park, Sawai Madopur, Rajasthan, India',
            desc:`Ranthambore Tiger Reserve was once a private Game Reserve of the Royal Family Of Jaipur.<br/><br/>
            Ranthambore Tiger Reseve is spread across 1334 sq km of Karauli and Sawai Madhopur districts of Rajasthan and the rivers Banas and Chambal are the lifeline for the flora and fauna of the Reserve.<br/><br/>
            The Reserve population of approximately 60 tigers along with a healthy population of leopards are the main attractions. Langurs striped hyenas sloth bears Caracals jungle cats chinkara chital deer sanbhar deer nilgai blackbuck and wild boar along with a variety of bird species are abundant and can be seen during jungle safari.<br/><br/>
            The Reserve can be reached by train to Sawai Madhopur railway station which is 20 kms from the Reserve.<br/><br/>
            Ranthambore Tiger Reserve has 10 safari zones and day safari is open all throughout the year in Zones 6,7,8,9 anx 10 and the rest of the Zones 1,2,3,4,5 are open from October 15 to June 30.`,
            bookdetails:'https://fmdss.forest.rajasthan.gov.in/MiddleWare/SubModuleFMDSS1?linkparam=YdgSMPLU6mrPPz6aVjWElAjRGk9WE1sdwTdQCGGBVRxaZOjYqEOdEQ=='
        },
        {
            id:5,
            title:'Kanha National Park, Balaghat, Madhya Pradesh, India',
            desc:'Kanha National Park desc',
            bookdetails:'https://forest.mponline.gov.in/'
        },
        {
            id:6,
            title:'Melghat National Park, Akola, Maharashtra, India',
            desc:'Melghat National Park desc',
            bookdetails:'https://www.magicalmelghat.in/'
        },
        {
            id:7,
            title:'Tadoba National Park, Chandrapur, Maharashtra, India',
            desc:'Tadoba National Park desc',
            bookdetails:'https://booking.mytadoba.org/safari/'
        },
        {
            id:8,
            title:'Sariska Tiger Reserve, Alwar, Rajasthan, India',
            desc:'Sariska Tiger Reserve desc',
            bookdetails:''
        },
        {
            id:9,
            title:'Pilibhit Tiger Reserve, Pilibhit City, Uttar Pradesh, India',
            desc:`Pilibhit Tiger Reserve in the Terai Region of Uttar Pradesh with an area of approximately 800 Sq km over Pilibhit Shahjahanpur districts of Uttar Pradesh. Rich in flora and fauna with tiger numbers upto 65, boosts of a healthy population of leopard wild boar chital sambhar sloth bear swamp deer etc. It has an iconic Chuka beach which offers excellent stay and safari options for tourists and nature lovers. On the periphery of the Reserve is the Sharda Sagar Dam and the Reserve shares its borders with Corbett and Dudhwa Tiger Reserves and Shuklapanta National Park of Nepal which are excellent animal corridors.<br/><br/>
            The Reserve is well connected from Delhi Bareilly and Lucknow and the entry gates are as follows:<br/><br/>
            Nehru Urja Udyan, Pilibhit<br/>
            Assam Chauraha, Pilibhit<br/>
            Sahid Surendra Singh Park, Puranpur<br/>
            Mustfabad Gate<br/>
            Mahon Gate<br/><br/>
            The Reserve is <b>open from November 1 to June 15 and the safari and Night stay</b> `,
            bookdetails:'https://upecotourism.in/EcoBookingSystem.aspx'
        },
        {
            id:10,
            title:'Dudhwa Tiger Reserve, Palia Kalan, Uttar Pradesh, India',
            desc:`Dudhwa Tiger Reserve, Palia Kalan, Uttar Pradesh, India Desc`,
            bookdetails:'https://upecotourism.in/DudhwaNationalPark.aspx'
        }
    ]

    return (
        <div className="min-h-full">
            <Header/>
            <div className="overflow-hidden">
                <h1 className="mt-10 text-center text-3xl font-bold">{Parks[params.id - 1].title}</h1>
                <div dangerouslySetInnerHTML={{ __html: Parks[params.id - 1].desc }} className="ml-5 mr-5 lg:pl-20 lg:pr-20 text-xl pt-10"></div>
                <p className="ml-5 mr-5 lg:pl-20 lg:pr-20 text-xl pt-10 mb-10">Safari Booking for {Parks[params.id - 1].title} official government website link : <a target="_blank" className="text-center text-blue-500 text-lg" href={Parks[params.id - 1].bookdetails}>{Parks[params.id - 1].bookdetails}</a></p>
            </div>
        </div>
    )
}